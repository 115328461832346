module.exports = {
  seo: {
      title : "Jenko - Article - How to Find Investors",
      description:  ""
  },
  intro: {
      title: "How to Find\u00A0Investors"
  },
  articleHeader: {
      desc: "When is the best timing to start looking for investors? What guidelines should you consider when preparing to raise capital? What mistakes should you avoid? Which strategies increase the chances of success?"
  },
  secP: {
      title: " It's all about timing",
      p: [
          "It’s not complicated to define the fairly common way of going from a brilliant idea in the mind to an active startup that generates actual profits. Although there are minor differences between industries, there is a process with clear guidelines that outlines the path for each entrepreneur, regardless of the nature or purpose of the product. One of the most significant questions that will determine whether the search for an investor bears fruit is the timing question.",
          'Searching for an investor is an individual process and as such, it has no rules. You can look for an investor at the idea stage, or when a prototype and business plan already exist, or when the business is already active and operational. The timing question has a big impact on the investor recruitment process. The rule of thumb is that when the hunt for an investor takes place at a more advanced stage of the product, or the more developed the product, the more likely it is to find investors and to raise capital. And that makes sense. Anyone would feel more comfortable putting their eggs in a basket that has actual receipts that indicate safety and viability than in a basket whose viability is questionable and it may very well be that the idea behind it turns out to be a foolishly\u00A0accepted\u00A0one.',
          "A common mistake that many entrepreneurs tend to make, for example, is recruiting early on, in exchange for too much of the company. This can make it difficult for future rounds of funding when other investors see how small the founder’s share of the company is and would prefer to continue with startups where the founder has greater control. As with everything in life, no matter at what stage the capital raising takes place, there will be pros and cons. Either way, in order to succeed in recruiting a serious investor you need to have a clear vision, clear business plan, prototype or a development team on the way to a prototype and manager or management team who knows how to navigate the ship."
      ]
  },
  thrdP: {
      title: "Laying\u00A0the\u00A0groundwork",
      p: [
          "Once the exact timing has been decided, it is worth laying the groundwork for investor recruitment. There is no telling when the right investor knocks on the door and sometimes this happens faster than you had imagined. You want to be prepared when it does happen otherwise you may miss the opportunity. Investor preparation can be divided into three parts: internal preparation, external preparation, and\u00A0investor\u00A0research.",
          'When we discuss the readiness of the entrepreneur, we refer to two aspects. One aspect is a prerequisite for continuing down the road and is focused on the level of thinking and vision. You need to be confident in your product. Understand its purpose, see in a clear and focused way its uniqueness and competitive advantage, or in other words, be able to easily put yourself in the investor’s shoes so that they can see exactly what you see. The second aspect focuses on the more practical part. A clear and detailed business plan that maps out the business model, organizational structure, and exact route that shows the tactics by which you will reach your goals. You need to map difficulties and hurdles that may emerge along the way and detailed backup plans that will serve as practical answers if they do. You will need to provide authoritative and reliable information based on deep research of your market, target audience, development costs, and\u00A0expected\u00A0profits.  ',
          "The second tier of the investor recruitment preparation process speaks to the handling of the external elements. If you have partners, the investor recruitment process should be done in full cooperation with them. Often, conflicts of interest emerge between investor and members of the entrepreneur’s management team. Late detection of such a thing can torpedo the entire deal and slow you down a few extra steps. Beyond that, consider that you might need professionals who support the process. A dedicated CPA will help you with accurate financial mapping, valuations, and costs. Such a professional can also assist in formulating the investor’s profile for you and helping you target it. You will also need legal services to anchor all relevant decisions among your team\u00A0and\u00A0investors.",
          "The third part of the way to be prepared for raising capital is choosing the right investors. Finding the most fitting investor is no less of an important task than finding the most fitting manager or developer. A team member can be replaced relatively easily while an investor is someone whose capital is the basis of your startup and is a little harder to replace. As always, it is advisable to consult professional officials who know how to direct you. The important parameters to consider when choosing an investor are the investor’s affinity for your industry, the compatibility of the amount of money you are seeking to raise to its level of economic resilience, the type and position of the investor, the stage you are in, the growth potential of both of you,\u00A0and\u00A0more."
      ]
  },
  ffthP: {
      title: "The final decision-making\u00A0stage",
      p: [
          'There is an idea, there is a vision, there is a prototype, a business plan, you’ve created a buzz and an interest. The moment has come, and appointments have been made with investors. An important principle that we tend to forget about is being organized. Manage the data that you collect from your recruitment process in an orderly and accessible manner. You should make a table to help you compare how much value the various investors are offering you. What is the financial potential? Where do they come from and what do they have to offer? What is their level of enthusiasm for you and what level of enthusiasm do you have for them? Get in the habit of updating this information at the end of each session while it is still fresh in\u00A0your\u00A0mind.',
          'You will be required to send investors an organized e-mail with presentation materials prior to the meeting. The rule of thumb is: The higher the quality and the more targeted the information, the higher chances of recruitment. The format in which you present the material should be tailored to the type of investors and of course to the nature of your industry. For example, if your product is related to digital design and innovation, you should incorporate innovative and original elements such as a video that shows the product and team. If, on the other hand, you are about to introduce the product to a solid group of investors, organize the materials in a more professional and restrained\u00A0manner.'
      ]
  },
  sixP: {
      p: 'The principle that should guide you when choosing how to present materials to investors is to “speak their language”. It is exceedingly difficult to impact a person if you do not speak the same language. Entrepreneurs often have the tendency to introduce innovative products in the product’s language rather than in their audience’s language. Remember who you’re presenting to. Take, for example, a startup whose product is an elaborate firefighter truck with built-in technology that can produce a 3D simulation during a fire. The visualization lets you see what’s going on inside a building without having to go inside. This way, the firefighters can map the area in seconds from the outside. This product can save lives and will certainly arouse great interest from firefighter managers. Now think who the fire chiefs are. How old they are, what do you think the technological knowledge at their disposal is and how deep discrepancies may be in the technology language between them and the developers. The chance of success in the meeting is directly proportional to your ability to bridge this gap. Do your homework and get ready for the specific investor audience that is in front\u00A0of\u00A0you.'
  },
  dir: 'ltr'
};